/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

html,
body {
  height: 100%;
  font-family: "Open Sans";
  margin: 0;
  --marker-dimension: 50px; // for carpark markers
  --nav-height: 80px;
  --primary-black-text-color: #222638;
  --secondary-black-text-color: #34404bcc;
  --primary-gray-text-color: #34404b;
  --primary-color-1: #68389a;
  --primary-color-2: #782eb1;
  --primary-bgcolor-1: #f5eefc;
  --primary-error-color: #e82370;
}

main {
  height: 100%;
}

button:hover {
  cursor: pointer;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.error-message {
  color: red;
  font-size: 0.7rem;
  padding: 0;
  margin: 0.5vmin 0 0 0;
}
.link-popup {
  color: var(--primary-color-2);
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 400;
}
// .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
//   font-size: 2rem;
//   display: none;
// }
// .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
//   right: 2.5vmin;
// }

// globally disable number type
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

app-carpark-list .carparklist-wrapper {
  .p-dialog .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 0 1.5rem 2rem 1.5rem;
    border-radius: 0 0 1.8vmin 1.8vmin;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon {
    color: #343a40;
    border-color: transparent;
    background: none;
    position: absolute;
    right: 2vmin;
    border-radius: 100px;
    border: 1px solid #000;
    font-size: 2vmin;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon {
    color: #343a40;
    border-color: transparent;
    background: #e9ecef;
    padding: 0;
    width: 2.5vmin;
    height: 2.5vmin;
    padding: 1.5vmin;
    margin: 0vmin;
    top: 3vmin;
    right: 3vmin;
    background: none;
    border: 0.1vmin solid #666;
    span {
      font-size: 1.8vmin;
    }
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background-color: none;
    border: 0.1vmin solid #666;
    background: none;
  }
}

app-broadcast-create > section:not(.new-createbroadcast-wrapper) {
  .p-dropdown-label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    flex: 1 1 auto;
    width: 1%;
    text-overflow: ellipsis;
    cursor: pointer;
    font-size: 1.8vmin;
    padding: 0;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 1.8vmin;
  }
  .p-dialog-content {
    overflow-y: inherit;
    flex-grow: 1;
  }
  .p-inputtext {
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    width: 100%;
  }
  .p-calendar {
    position: relative;
    display: inline-flex;
    max-width: 100%;
    width: 95%;
  }
  .p-dropdown {
    position: relative;
    display: inline-flex;
    max-width: 100%;
    width: 100%;
    border: none;
    border-bottom: 0.1vmin solid #ccc;
    border-radius: 0;
    margin-bottom: 2vmin;
  }
}

.icon-container {
  img {
    width: 8vmin;
  }
}
.input-textfield:enabled:focus {
  box-shadow: none;
}
.input-textfield {
  border-radius: 0;
}
.label-input {
  font-size: 14px;
  line-height: 20px;
  color: #778188;
  margin-top: 30px;
}
.form-control label {
  font-size: 16px !important;
  font-weight: 400 !important;
}
.label-input > label {
  left: 0;
  font-size: 14px;
  font-weight: 400 !important;
}
// :not(.publish-to-breeze-checkbox, .new-createbroadcast-wrapper div).p-checkbox
//   .p-checkbox-box {
//   border: 2px solid #ced4da;
//   width: 2.8vmin;
//   height: 2.8vmin;
//   border-radius: 0.5vmin;
//   position: relative;
//   top: 0.3vmin;
// }

.p-tabview-title {
  line-height: 1;
  white-space: nowrap;
  font-size: 2vmin;
}

app-uploaded-files-preview {
  .p-image-preview-container {
    img.preview-image-class {
      border-radius: 8px;
      object-fit: cover;
      // max-height: 166px;
      // max-width: 286px;
      height: 166px;
      width: 286px;
    }
  }
}

app-carpark-preview-web {
  .cp-list-btn {
    span {
      display: none;
    }
  }
  .p-button {
    color: #ffffff;
    background: var(--primary-color-1);
    border: 1px solid var(--primary-color-1);
    padding: 2vmin 6vmin;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 0.9vmin;
    font-size: 1.8vmin;
    font-weight: 600;
    font-family: "Open Sans";
    &:hover {
      background: var(--primary-color-1);
    }

    // span {
    //   display: none;
    // }
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin: 0 !important;
  }
}
app-forgot-password-email {
  .error-message {
    position: relative;
    top: -17px;
  }
}

app-carpark-price {
  .p-tabview-nav-link {
    border: none !important;
  }

  .p-datepicker .p-timepicker span {
    font-size: 1.8vmin;
    text-align: center;
  }

  .p-datepicker .p-timepicker > div {
    padding: 0;
  }

  .w-30 {
    width: 30vmin;
    padding-right: 2vmin;

    .p-dropdown {
      width: 100%;
    }
  }

  .p-component,
  .p-component * {
    font-size: 17px;

    h2 {
      display: flex;
      align-items: center;
      div {
        font-size: 2.8vmin;
        max-width: 80%;
      }
    }
  }

  .p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: #6c757d;
    width: 3vmin;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  // .p-dropdown .p-dropdown-label {
  //   font-size: 1.8vmin;
  // }

  .p-dropdown-item {
    font-size: 1.8vmin;
  }

  .p-highlight .panel-header {
    color: var(--primary-color-2) !important;
  }
}

.p-datepicker {
  padding: 0;
}

app-carpark-create-main {
  .parking {
    i {
      font-size: 2vmin;
      margin-left: 2vmin;
      color: var(--primary-color-1);
    }
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    right: 2.5vmin;
    position: absolute;
    margin: 3vmin 0 0 0;

    &:focus {
      background: none;
      outline: none;
    }
    .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
      background: none;
    }
    .pi-times:before {
      content: "\e90b";
      font-size: 1.5vmin;
      color: #ccc;
      border: 0.4vmin solid #ccc;
      border-radius: 100px;
      padding: 0.6vmin;
      font-weight: 600;
      margin: -5vmin 0 0 0;
      top: 0;
    }
  }
}

// .p-dialog .p-dialog-header {
//   padding: 20px 10px 0 0 !important;
// }

//#region markers and icons css
%marker-common {
  background-size: cover;
  width: var(--marker-dimension);
  height: var(--marker-dimension);
  border-radius: 50%;
  cursor: pointer;
}
.pi-map-marker:before {
  font-size: var(--marker-dimension, 1rem);
}
.destination-marker {
  cursor: pointer;
  font-size: 3.5rem;
}
.mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
  opacity: 0 !important;
}
.carpark-marker-mobile {
  @extend %marker-common;
  background-image: url("/assets/images/icons/carpark-mobile-icon.svg");
  width: 30px;
  height: 60px;
  background-position: center;
  border-radius: 0;
  background-size: contain;
  background-repeat: no-repeat;
}
.carpark-marker-icon {
  cursor: pointer;
}
.create-custom-pin-modal {
  .p-dialog-content {
    min-width: 800px;
  }
}
/* todo: delete the following classes if they are not being used anymore.. */
.carpark-marker--entry {
  background-image: url("/assets/images/icons/carpark-entry.svg");
  @extend %marker-common;
}
.carpark-marker--exit {
  background-image: url("/assets/images/icons/carpark-exit.svg");
  @extend %marker-common;
}
.carpark-marker--pickup {
  background-image: url("/assets/images/icons/carpark-pickup.svg");
  @extend %marker-common;
}
.carpark-marker--emergency {
  background-image: url("/assets/images/icons/carpark-pickup.svg");
  @extend %marker-common;
}
.carpark-marker--selfAssembly {
  background-image: url("/assets/images/icons/carpark-pickup.svg");
  @extend %marker-common;
}
.carpark-marker--custom {
  background-image: url("/assets/images/icons/carpark-pickup.svg");
  @extend %marker-common;
}

.custom-cursor--entry {
  --carpark-custom-cursor: url("/assets/images/icons/carpark-entry.svg"), auto;
}
.custom-cursor--exit {
  --carpark-custom-cursor: url("/assets/images/icons/carpark-exit.svg"), auto;
}
.custom-cursor--pickup {
  --carpark-custom-cursor: url("/assets/images/icons/carpark-pickup.svg"), auto;
}
.custom-cursor--emergency {
  --carpark-custom-cursor: url("/assets/images/icons/carpark-pickup.svg"), auto;
}
.custom-cursor--selfAssembly {
  --carpark-custom-cursor: url("/assets/images/icons/carpark-pickup.svg"), auto;
}
.custom-cursor--custom {
  --carpark-custom-cursor: url("/assets/images/icons/carpark-pickup.svg"), auto;
}
// new markers below
%new-marker {
  @extend %marker-common;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.entry-map-marker {
  @extend %new-marker;
  background-image: url("/assets/images/icons/entry-map-marker.svg");
}
.exit-map-marker {
  @extend %new-marker;
  background-image: url("/assets/images/icons/exit-map-marker.svg");
}
.pickup-map-marker {
  @extend %new-marker;
  background-image: url("/assets/images/icons/pickup-map-marker.svg");
}
.red-map-marker {
  @extend %new-marker;
  background-image: url("/assets/images/icons/red-map-marker.svg");
}
.gray-map-marker {
  @extend %new-marker;
  background-image: url("/assets/images/icons/gray-map-marker.svg");
}

.main-content.custom-cursor-active {
  cursor: var(--carpark-custom-cursor);
  .mapboxgl-canvas-container.mapboxgl-interactive {
    cursor: var(--carpark-custom-cursor);
  }
}
.initial-location-marker {
  background-image: url("/assets/images/icons/carpark-pickup.svg");
  @extend %marker-common;
  width: 7vmin;
  height: 7vmin;
}
.price--calender {
  background-image: url("/assets/images/icons/calender-outline.svg");
  background-size: 2.5vmin;
}

.price--clock {
  background-image: url("/assets/images/icons/clock-outline.svg");
  background-size: 2.5vmin;
}

.price--times {
  background-image: url("/assets/images/icons/clock-outline-2.svg");
  background-size: 2.5vmin;
}

.price--dolorprice {
  background-image: url("/assets/images/icons/dolor-outline.svg");
  background-size: 1.8vmin;
}

.feature--parklot {
  background-image: url("/assets/images/icons/icon-class-1.svg");
  width: 10vmin;
}
.feature--parktype {
  background-image: url("/assets/images/icons/icon-class-2.svg");
}
.feature--location {
  background-image: url("/assets/images/icons/icon-class-3.svg");
}
.feature--handicap {
  background-image: url("/assets/images/icons/icon-class-4.svg");
}
.feature--Evcharge {
  background-image: url("/assets/images/icons/icon-class-5.svg");
}
.feature--operationhour {
  background-image: url("/assets/images/icons/icon-class-6.svg");
}
.feature--cashcard {
  background-image: url("/assets/images/icons/icon-class-7.svg");
}
.feature--valet {
  background-image: url("/assets/images/icons/icon-class-8.svg");
  background-size: 2vmin;
}
.feature--motorcycle {
  background-image: url("/assets/images/icons/icon-class-9.svg");
}
.feature--paymenttype {
  background-image: url("/assets/images/icons/icon-class-10.svg");
}
.feature--cargroom {
  background-image: url("/assets/images/icons/icon-class-11.svg");
}
.feature--parklot {
  background-image: url("/assets/images/icons/icon-class-12.svg");
}

.price-calender {
  background-image: url("/assets/images/icons/calender-outline.svg");
}

.price-clock-outline {
  background-image: url("/assets/images/icons/clock-outline.svg");
}

.price-clock-outline-time {
  background-image: url("/assets/images/icons/clock-outline-2.svg");
}

.price-dolor {
  background-image: url("/assets/images/icons/dolor-outline.svg");
}
// #endregion

///Tost//
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 3vmin;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
  font-size: 2vmin;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
  font-size: 1.8vmin;
}

.p-toast-top-center {
  top: 15vmin !important;
  left: 50%;
  transform: translate(-50%);
}

.p-toast {
  position: fixed;
  width: 41vmin;
}
///Tost//

.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 6vmin;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
  padding: 1.5vmin 0 0 1vmin;
}

.p-link:disabled {
  cursor: default;
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  height: 2vmin;
}

app-broadcast-list {
  .p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 5vmin;
    height: 5vmin;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 50%;
    font-size: 2vmin;
  }
  .p-paginator .p-dropdown {
    height: 5vmin;

    .p-dropdown-trigger {
      width: 4vmin;
    }

    .p-dropdown-label {
      padding: 1vmin;
    }
  }

  .p-calendar {
    position: relative;
    display: block;
    max-width: 90%;

    .p-inputtext {
      flex: 1 1 auto;
      width: 100%;
      font-size: 1.8vmin;
      border: none;
      border-bottom: 1px solid #ccc;
      border-radius: 0;
      padding: 1vmin 0;
    }
  }

  .p-dialog .p-dialog-header {
    padding: 0;
    position: absolute;
    right: 0;

    .p-dialog-header-close-icon {
      border: 0.3vmin solid #adadad;
      font-size: 1.8vmin;
      padding: 0.5vmin;
      border-radius: 100vmin;
      font-weight: 600;
      color: #adadad;
    }
  }
  .p-dropdown {
    width: 100%;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    padding: 0;
    margin-bottom: 1vmin;
    height: 4vmin;
  }
  .p-datepicker table td {
    padding: 0;
    font-size: 1.8vmin;
  }
  .p-datepicker table th > span {
    font-size: 2vmin;
  }
  .p-datepicker .p-timepicker span {
    font-size: 1.8vmin;
  }
  .p-calendar .p-datepicker {
    min-width: auto;
  }
  .p-dialog .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 4vmin;
    border-radius: 15px;
    overflow: visible;
  }
}

app-carpark-price-preview {
  .p-tabview .p-tabview-nav {
    border-bottom: none;
    .p-tabview-title {
      color: #bcbfc3;
      font-weight: 600;
    }

    .p-highlight .p-tabview-title {
      color: var(--primary-color-2);
    }
    li .p-tabview-nav-link {
      background: transparent !important;
      border-bottom: 3px solid #d6d9db !important;
    }
    li.p-highlight .p-tabview-nav-link {
      background: transparent !important;
      border-bottom: 3px solid var(--primary-color-2) !important;
    }
  }
}

app-availability-integrate {
  .p-tabview .p-tabview-nav {
    border-bottom: none;
    .p-tabview-title {
      color: var(--primary-gray-text-color);
      font-weight: 600;
      font-size: 16px;
    }

    .p-highlight {
      background: #f7f7f8 !important;
      .p-tabview-title {
        color: var(--primary-color-2);
      }
    }
    li .p-tabview-nav-link {
      background: transparent !important;
      border-bottom: none !important;
    }
    li.p-highlight .p-tabview-nav-link {
      background: transparent !important;
      border-bottom: none !important;
    }
  }
}

app-add-initial-location {
  .p-autocomplete {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #ccc;
    position: relative;
    display: inline-block;
    width: 100%;
    &:focus {
      outline: none;
      border: none;
    }
    .p-inputtext {
      margin: 0;
      width: 100%;
      display: inline-block;
      width: 100%;
      font-size: 1.8vmin;
      border-radius: 0;
      padding: 1vmin 0 1vmin 0;
      &:focus {
        outline: none;
        border: none;
      }
    }
  }
}

carpark-feature-main {
  .p-datepicker .p-timepicker span {
    font-size: 1.8vmin;
  }
  .p-field-checkbox {
    display: flex;
    align-items: flex-start;
  }
}

app-carpark-create-main {
  .p-autocomplete {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #ccc;
    position: relative;
    display: inline-block;
    width: 100%;
    &:focus {
      outline: none;
      border: none;
    }
    .p-inputtext {
      margin: 0;
      width: 100%;
      border: none;
      display: inline-block;
      width: 100%;
      border: none;
      font-size: 1.8vmin;
      border-radius: 0;
      padding: 1vmin 0 1vmin 0;
    }
  }
}
app-navbar {
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-text {
    color: #772eaf;
  }
  .p-menuitem-link-active {
    .p-menuitem-text {
      color: #772eaf;
    }
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link-active {
    span {
      color: var(--primary-color-1) !important;
    }
  }
}

.navbar-notification-overlay.p-overlaypanel {
  .p-overlaypanel-content {
    padding: 0;
  }
}
app-carpark-list .carparklist-wrapper {
  .p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 5vmin;
    height: 5vmin;
    font-size: 2vmin;
  }

  .p-inputtext {
    font-size: 2vmin;
    padding: 0.75vmin 0.75vmin;
  }
  .p-paginator .p-dropdown {
    margin-left: 1vmin;
    margin-right: 1vmin;
    height: 5vmin;
  }
  .p-dropdown-trigger-icon {
    font-size: 2vmin;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 1vmin;
    font-size: 2vmin;
  }
}

.location-marker-tooltip {
  height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .mapboxgl-popup-tip {
    display: none;
  }

  * {
    background-color: transparent;
    border: none;
  }

  .mapboxgl-popup-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    pointer-events: none;
    font-size: 1rem;
    gap: 75px;
    position: relative;
    top: 15px;
    box-shadow: none;
    > * {
      text-align: center;
    }
    .top-container {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      gap: 0.5rem;
      border: 1.5px solid #9aa0a5;
      box-sizing: border-box;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
      border-radius: 16px;
      background: #ffffff;
      .pi-map-marker {
        --marker-dimension: 1.2rem;
        font-size: 1.2rem;
      }
    }
    .bottom-container {
      padding: 1.5vmin 3vmin;
      color: #ffffff;
      background: #34404b;
      border-radius: 1vmin;
    }
  }
}
//#endregion

// #region code block line number rendering
.code-block-container pre {
  margin: 0;
  padding: 5px;
  white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
  white-space: -pre-wrap; /* Opera */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* CSS3 – Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
  word-wrap: break-word; /* IE 5.5+ */
  code {
    counter-reset: line-numbering;
    display: block;

    .code-line::before {
      content: counter(line-numbering);
      counter-increment: line-numbering;
      padding-right: 0.8em; /* space after numbers */
      margin-right: 1em;
      width: 1.5em;
      text-align: right;
      opacity: 0.5;
      background-color: #efefef;
      padding: 0 20px 0 10px;
    }
  }
}

.lastdiv a::after {
  display: none;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 2vmin;
}
.p-menubar .p-menubar-end i:nth-child(2) {
  display: none;
}

.p-menubar .p-menuitem-link .p-menuitem-text {
  line-height: 20px;
  font-size: 16px;
}

// #endregion

* {
  font-family: "Open Sans";
}
button {
  border: none;
}

ul {
  margin: 0;
}

.p-tabview .p-tabview-panels {
  background: #f7f7f8 !important;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #f7f7f8 !important;
}
.p-tabview-title {
  color: var(--primary-color-1);
}

.sidebar-price-wrapper {
  .p-tabview .p-tabview-panels {
    background: none !important;
  }
}

// #region date-picker
// disable month and year selection for primeng dateTimeRangepicker since its seems bugged
// todo: check later to fix
button {
  &.p-datepicker-year,
  &.p-datepicker-month {
    pointer-events: none;
  }
}
#datetime_range {
  height: 0;
  padding: 0;
  border: 0;
}
// #endregion

.feature-sidebar.wrapper {
  p {
    margin: 0;
  }
}

.progress-spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 99998;
  opacity: 1;
  display: block;
}
p-progressspinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inherit;
}

app-carpark-create-main {
  .p-dialog {
    min-width: 392px;

    background: #fff;
    .p-dialog-header {
      padding: 20px 0 0 0 !important;
      right: 0;
      position: relative;

      .p-dialog-title {
        font-weight: 700;
        font-size: 2.8vmin;
        width: 100%;
        text-align: center;

        .p-dialog-header-icons {
          display: flex;
          align-items: center;
          position: absolute;
          right: 0;
        }

        .p-dialog-header-icon:last-child {
          margin-right: 0;
          position: absolute;
          right: 10px;
        }
      }
    }

    .p-confirm-dialog .p-confirm-dialog-icon {
      display: none !important;
    }
    .p-confirm-dialog .p-confirm-dialog-message {
      text-align: center;
      width: 100%;
      font-size: 1.8vmin;
      margin: 0 !important;
    }

    .p-dialog-footer button {
      margin: 0 0.5rem 0 0;
      width: auto;
      background: var(--primary-color-1);
      font-size: 1.8vmin;
      padding: 1.5vmin 4vmin;
      &:nth-child(1) {
        background: #9aa0a5;
      }
      &:hover {
        background: var(--primary-color-1);
      }
      &:focus {
        outline: none;
        border: none;
      }
      .pi {
        font-size: 1.8vmin;
      }
    }

    .p-dialog-footer {
      text-align: center;
    }
  }
}

sidebar-feature {
  // margin: 0 0 0 3vmin;
  .data {
    font-weight: 600;
    p {
      margin-top: 0;
      margin-bottom: 0.5vmin;
      margin-right: 1vmin;
    }
    p,
    div,
    strong {
      font-weight: 600;
    }
  }
}

app-carpark-features {
  .p-dialog .p-dialog-header {
    padding: 0 10px 0 0 !important;
  }
  .p-calendar .p-inputtext {
    border: 1px solid #9aa0a5 !important;
  }

  .p-dialog.image-cropper-modal {
    position: fixed;
    min-width: max(44vmin, 70vw);
    min-height: max(44vmin, 35vw);
    .image-cropper-footer {
      padding-top: 2vmin;
    }
  }
  .p-dialog .p-dialog-header .p-dialog-title {
    padding: 3vmin 0 0 0;
  }
  .p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
    background: var(--primary-color-1);
    border-radius: 1vmin;
    color: #fff;
  }

  .p-radiobutton .p-radiobutton-box {
    &.p-highlight {
      background-color: var(--primary-color-2);
      border-color: var(--primary-color-2);
      .p-radiobutton-icon {
        transform: translateZ(0) scale(0.7);
      }
    }
  }
  .p-checkbox .p-checkbox-box {
    border-radius: 2px;
    &.p-highlight {
      background-color: var(--primary-color-2);
      border-color: var(--primary-color-2);
    }
  }

  .op-hour-field p-calendar input:disabled {
    background-color: #e2e2e2;
  }
}

app-carpark-price {
  .p-dialog .p-dialog-header {
    padding: 20px 0 0 0 !important;
    right: 0;
    position: absolute;
  }
}
app-carpark-entrances {
  .p-dialog.create-custom-pin-modal {
    border-radius: 24px;
    padding: 24px;
  }

  .mapboxgl-popup.location-pin-map-tooltip {
    .location-pin-info-tooltip {
      padding: 8px 16px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
      border: 0.4px solid var(--primary-color-2);
      background-color: var(--primary-bgcolor-1);
      border-radius: 12px;
      font-weight: 400;
      font-size: 14px;
      position: relative;
      &::after,
      &::before {
        content: "";
        position: absolute;
        left: 30px;
        top: calc(100% - 1px);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #f5eefc;
        clear: both;
      }
      &::after {
        top: calc(100% - 1px);
        border-top: 10px solid #f5eefc;
      }
      &::before {
        top: calc(100%);
        border-top: 10px solid #782eb1;
      }
    }
    .mapboxgl-popup-content {
      background: none;
      box-shadow: none;
      position: relative;
      top: -95px;
      left: 75px;
      padding: 0;
    }
    .mapboxgl-popup-tip {
      display: none;
    }
  }
}

app-carpark-create-main {
  .p-dialog-mask.p-component-overlay {
    pointer-events: auto;
    z-index: 900 !important;
  }
}

// popup for mobile preview view
.mobile-carpark-marker-tooltip {
  max-width: 400px !important ;
  .mapboxgl-popup-content {
    background: none;
    box-shadow: none;
    position: relative;
    top: -20px;
  }
  .mapboxgl-popup-tip {
    display: none;
  }
}
.pricing-details-mobile-preview {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 16px;
  color: black;
  border: none;
  font-size: 16px;
  > * {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .infotable {
    width: initial;
  }

  .top-section {
    margin-bottom: 9px;
    .cheapest-tag {
      color: #15b765;
      border: 0.4px solid #15b765;
      font-weight: 500;
      font-weight: 500;
      font-size: 14px;
      border-radius: 8px;
      padding: 5px 10px;
      background: #edfdf5;
      border-radius: 12px;
    }
    .bookmark-btn {
      background-image: url("/assets/images/icons/bookmark-icon.svg");
      background-size: contain;
      width: 13px;
      height: 20px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  h4.tsname {
    margin: 0;
    text-align: left;
    margin-bottom: 10px;
    display: inline-block;
  }
  .btn {
    border: 0.6px solid var(--primary-color-2);
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;
    height: 28px;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    &.btn--calculate {
      background-color: transparent;
      color: var(--primary-color-2);
    }
    &.btn--navigate {
      background-color: var(--primary-color-2);
      color: #fff;
    }
    &.btn--see-more {
      border: none;
      background-color: transparent;
      color: var(--primary-color-2);
    }
  }
  table.infotable {
    min-width: 183px;
    tr {
      height: 20px;
    }
    td {
      &.parking-sign > i {
        background-color: var(--primary-color-2);
      }
      &.dollar-sign > i {
        background-color: #50b471;
      }
    }
    td:nth-child(2) {
      min-width: 85px;
    }
    td:last-child {
      min-width: 70px;
      text-align: right;
      font-weight: bold;
      &.free {
        color: #50b471;
      }
    }
  }
  i {
    width: 28px;
    height: 28px;
    border-radius: 100px;
    background-color: #cbc;
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 28px;
    font-style: normal;
    margin: 0 9px 0 0;
  }
}

app-usage-table {
  .p-datepicker table td {
    padding: 0;
    font-size: 1.8vmin;
  }
  .p-datepicker .p-timepicker span {
    font-size: 1.8vmin;
  }
  .p-datepicker table th > span {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.8vmin;
  }
  .p-datepicker table th {
    padding: 0;
  }
  .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #fafafa;
  }
  .p-datepicker:not(.p-datepicker-inline) {
    background: #fafafa;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  }
  .p-datepicker table td > span {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 11px;
    transition: box-shadow 0.2s;
    border: 1px solid transparent;
    background: #fff;
    margin: 0.1vmin;
  }
  .p-datepicker table td > span {
    width: 6vmin;
    height: 6vmin;
    border-radius: 1vmin;
    transition: box-shadow 0.2s;
    border: 1px solid transparent;
    background: #fff;
    margin: 0.5vmin;
  }
  .p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
    padding: 1.5vmin 0 0 1vmin;
    font-size: 1.8vmin;
  }
  .p-paginator {
    padding: 0;
  }
  .p-paginator .p-dropdown {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 5vmin;
    margin: 1vmin;
  }
  .p-disabled,
  .p-disabled * {
    font-size: 2vmin;
  }
}

// style for all dynamic dialog
p-dynamicdialog {
  // confirm-trigger-popup style
  .confirm-trigger-popup.p-dialog {
    // custom styleClass that is passed
    &.verification-popup {
      .p-dialog-header {
        display: none;
      }
      .p-dialog-content {
        padding: 2vmin;
        border-radius: 1vmin;
      }
    }
  }
}

app-forgot-password-verify {
  code-input {
    input {
      height: 6vmin !important;
      margin-bottom: 1vmin;
    }
    span {
      margin-right: 1vmin;
    }
  }
}

.createAccount-help-p-dialog {
  .p-dialog-content,
  .p-dialog-header {
    border-radius: 24px;
  }
}

.support-page-wrapper {
  .support-image-container {
    max-width: 60%;
    border-radius: 10px;
    border: 1px solid black;
    max-height: 400px;
    .support-image {
      width: 100%;
      border-radius: 10px;
      max-height: calc(400px - 2px);
    }
    .p-image-mask {
      overflow: auto;
    }
    .p-image-toolbar {
      z-index: 2105;
      background-color: gray;
    }
    .p-image-preview-indicator {
      border-radius: 10px;
    }
  }
}
.carpark-entry-wrapper {
  .carpark-entry-panel {
    .p-panel-header {
      background-color: white;
      border-bottom: none;
      .p-panel-icons-end {
        position: absolute;
        left: 30px;
        top: 30px;
        button {
          span {
            font-weight: 800;
            font-size: 27px;
            &.pi-angle-right {
              transform: rotate(90deg);
            }
            &.pi-angle-down {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .p-panel-header,
    .p-panel-content {
      padding-left: 70px;
    }
  }
}
.carpark-season-parking-tab {
  .period-dropdown {
    width: 100%;
  }
}

.broadcastlist-wrapper {
  .sort-type-dropdown,
  .filters-dropdown {
    border: 1px solid #d6d9db;
    border-radius: 12px;
    height: 48px;
    margin-bottom: 0;
    span.p-element {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .filters-dropdown .p-dropdown-items-wrapper {
    max-height: initial !important;
  }
}

.carpark-utilisation-page-wrapper {
  .p-dropdown.p-component {
    &.carpark-dropdown {
      min-width: 430px;
      min-height: 48px;
      background: #ffffff;
      border-radius: 16px;
      border: none;
    }
  }

  .utilization-report-container,
  .utilization-forecast-report-container {
    p-chart {
      flex: 1;
      div {
        height: 100%;
      }
    }
  }
}

.admin-create-account-wrapper,
.admin-carpark-list-wrapper,
.create-voucher-wrapper {
  .carpark-multiselect.p-multiselect.p-component {
    width: 100%;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 0;
    box-shadow: none;
    .p-multiselect-label {
      padding-left: 0;
      padding-right: 0;
    }
    .p-multiselect-trigger:not(.create-voucher-wrapper *) {
      justify-content: flex-end;
    }
    .p-checkbox-box {
      border-radius: 4px;
      &.p-highlight {
        background-color: #3d7fe2;
        border-color: #3d7fe2;
      }
    }
    .p-multiselect-token {
      background-color: #f8f5fb;
      .p-multiselect-token-label,
      .p-multiselect-token-icon {
        color: #000;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

.admin-carpark-list-wrapper {
  .p-dialog.assign-operator-modal {
    width: 43%;
    min-width: 820px;
    padding: 24px;
    background: #fff;
    border-radius: 24px;
    .p-dialog-header {
      padding: 0 !important;
    }
    .p-dialog-content {
      overflow: visible;
      padding: 0;
    }
    .operator-dropdown.p-dropdown.p-component {
      width: 100%;
      margin-top: 0;
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 0;
      box-shadow: none;
      .p-dropdown-label {
        padding-left: 0;
        padding-right: 0;
      }
      .p-dropdown-trigger {
        justify-content: flex-end;
      }
    }

    .p-multiselect.p-component {
      margin-top: 0;
    }
    .p-multiselect-token {
      background-color: #f8f5fb;
      padding: 3px;
      .p-multiselect-token-label,
      .p-multiselect-token-icon {
        color: #000;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .p-dropdown-label,
    .p-multiselect-label {
      padding-bottom: 6px;
    }
    &.p-dialog .p-dialog-header .p-dialog-header-icon {
      border: 1px solid;
    }
  }
}

.p-dialog.generic-confirm-modal {
  overflow: hidden;
  border-radius: 14px;
  .p-dialog-header {
    display: none;
  }
  .p-dialog-content {
    padding: 0 !important;
    overflow: hidden;
  }
}

.carpark-entry-wrapper {
  p-dropdown,
  p-calendar {
    display: flex;
    > .flex-field {
      flex: 1;
    }
  }
  .flex-field {
    flex: 1;
  }
}

.help-popover {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  font-size: 16px;
  border: 1px solid var(--primary-color-2);
  border-radius: 50%;
  background-color: #ffffff;
  color: var(--primary-color-2);

  app-help-popover {
    display: none;
    position: absolute;
    bottom: 30px;
    left: -12px;
  }

  &:hover app-help-popover {
    display: block;
  }
}

.image-popover {
  .turn-around {
    transform: rotate(180deg);
    font-size: 12px !important;
    .content {
      padding: 5px !important;
      min-width: 150px !important;
      font-size: 12px;
    }
    &.voucher-action-popover {
      white-space: nowrap;
      .content {
        min-width: initial !important;
        font-style: normal;
      }
    }
  }
  app-help-popover {
    display: none;
    position: absolute;
    top: 40px;
    right: -8px;
    z-index: 10px;
    .content {
      padding: 0px;
    }
  }

  &:hover app-help-popover {
    display: block;
  }
}

.carpark-validation-popup {
  padding: 75px;
  border-radius: 24px;
  .p-dialog-content {
    display: flex;
    flex-direction: column;
  }
}
// global styles for form control and error component
.form-control {
  position: relative;
  padding-right: 5%;
  margin-bottom: 32px;

  &.password,
  &.suffix-image {
    img {
      position: absolute;
      top: 40px;
      right: 6%;
      z-index: 10;
      cursor: pointer;
    }
  }

  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #778188;
    display: block;
  }
  input,
  select {
    margin-top: 8px;
    border: none;
    border-bottom: 1px solid #9aa0a5;
    padding-bottom: 8px;
    background: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #222638;
    width: 100%;
    &:focus {
      outline: none;
      border-bottom: 1px solid var(--primary-color-2) !important;
    }
    &:disabled {
      border-color: transparent !important;
    }
    &::placeholder {
      color: #bcbfc3;
      font-weight: 400;
      font-size: 16px;
    }
  }
  i {
    position: absolute;
    color: var(--primary-color-1);
    margin: 0 0 0 -20px;
  }
  &.faded {
    label,
    input {
      color: #e2e2e2;
      border-color: #e2e2e2;
    }
  }
}
.form-control,
.form-control-element {
  .error-text {
    display: none;
  }
  &.error {
    input {
      border-color: #e82370 !important;
    }
    label,
    .error-text {
      color: #e82370 !important;
    }
    .error-text {
      display: block;
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      margin-top: 4px;
      p {
        font-style: italic;
        margin: 0;
        margin-top: 5px;
      }
    }
  }
}

// hide ink bar on rates and rates preview primeNg tabs to prevent wonky behaviour
.rates-preview-tabs,
.rates-tabs,
.integrate-availability-tabs {
  .p-tabview-nav .p-tabview-ink-bar {
    display: none;
  }
}

.approve-reject-confirmation-dialog {
  max-width: 75vw;
  padding: 20px;
  background: #fff;
  .p-dialog-footer button {
    margin: 0 8px 0 0;
    width: auto;
    background: var(--primary-color-1);
    font-size: 16px;
    padding: 16px 4px;
    &:nth-child(1) {
      background: #9aa0a5;
    }
  }
}
.reject-ok-button {
  background: var(--primary-color-1) !important;
  span:first-child::before {
    // display: none;
    content: "\e909";
  }
}

app-broadcast-create > section.new-createbroadcast-wrapper {
  .carpark-dropdown.p-dropdown {
    width: 100%;
    border: 0;
    border-radius: 0;
    // border-bottom: 1px solid #ced4de;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.5);
  }

  .p-calendar .p-inputtext {
    box-shadow: none;
    border: 1px solid #d6d9db;
  }
  .isTimed-checkbox .p-checkbox-box {
    border-radius: 4px;
  }
  .carpark-select--broadcast {
    .p-dropdown {
      width: 100%;
    }
  }

  &.preview {
    .p-disabled,
    .p-component:disabled {
      opacity: 1;
    }
    input {
      pointer-events: none;
      border: none;
      font-weight: 400;
      font-size: 16px;
      color: #222638;
      opacity: 1;
    }
    p-dropdown .p-dropdown {
      border: none;
      .p-element {
        padding-left: 0;
      }
      .p-dropdown-trigger {
        display: none;
      }
      .p-dropdown-label.p-inputtext {
        padding: 0;
        font-weight: 400;
        font-size: 16px;
        color: #222638;
        opacity: 1;
      }
    }
    p-radiobutton label {
      margin-left: 0;
      opacity: 1;
      font-weight: 400;
      font-size: 16px;
      color: #222638;
    }
    .form-control {
      margin: 0;
      margin-bottom: 32px;
      &.message-textarea-control {
        margin-bottom: 0;
      }
    }
    textarea {
      background: none;
      border: none !important;
      resize: none;
      padding: 0 !important;
      margin-bottom: 0;
      font-weight: 400;
      font-size: 16px;
      color: #222638;
      opacity: 1;
    }
    p-radiobutton.preview .p-radiobutton.p-component {
      display: none;
    }
    p-radiobutton.preview-hidden {
      display: none;
    }
    .p-calendar input {
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 16px;
      color: #222638;
      opacity: 1;
    }
  }
}

.form-field {
  .include-prefix,
  .include-suffix {
    position: relative;
    input {
      width: 100%;
      padding-left: 23px;
    }

    &::before,
    &::after {
      color: #778188;
      position: absolute;
      top: 13px;
    }
  }
  .include-prefix::before {
    content: attr(data-prefix);
    left: 10px;
    top: 11px;
  }
  .include-suffix::after {
    content: attr(data-suffix);
    right: 10px;
  }
}
::placeholder {
  color: #bcbfc3;
  opacity: 1; /* Firefox */
}
.time-error {
  border: 1px solid #e82370;
}

app-confirm-initial-location {
  .p-dialog {
    border-radius: 16px;
    overflow: hidden;
  }
}

app-create-account .form-control {
  &.checkbox {
    .p-checkbox .p-checkbox-box {
      border-radius: 2px;
      &.p-highlight {
        border-color: var(--primary-color-2);
        background-color: var(--primary-color-2);
      }
    }
  }
}

//Note: apply following class appropriately to get parkeasy themed components

.prkesy-theme {
  .p-radiobutton .p-radiobutton-box {
    &.p-highlight {
      background-color: var(--primary-color-2);
      border-color: var(--primary-color-2);
      .p-radiobutton-icon {
        transform: translateZ(0) scale(0.7);
      }
    }
  }
  &.p-checkbox .p-checkbox-box {
    border-radius: 2px;
    &.p-highlight {
      background-color: var(--primary-color-2);
      border-color: #782eb1;
    }
  }

  &:is(button) {
    background-color: #782eb1;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    padding: 16px 0;
    min-width: 150px;
    border-radius: 8px;
    color: #fff;
  }
}

.carpark-utilisation-page-wrapper {
  p-dropdown {
    max-width: 80%;
    min-width: 400px;
    .availability-carpark-dropdown {
      width: 100%;
      .p-dropdown-panel {
        max-width: 100%;
      }
    }
  }
}

app-create-voucher {
  .create-voucher-wrapper {
    .form-control {
      input,
      .carpark-multiselect.p-multiselect.p-component,
      .p-dropdown.p-component {
        &.p-focus {
          box-shadow: none;
          border: 1px solid #778188;
        }
        width: 100%;
        border: 1px solid #d6d9db;
        border-radius: 8px;
        padding: 12px;
        font-weight: 400;
        font-size: 16px;
        .p-inputtext:not(.p-multiselect-filter),
        .p-multiselect-label {
          padding: 0;
        }
      }
      .p-calendar {
        width: 100%;
        input {
          padding-left: 32px;
        }
      }
      input:focus,
      select:focus {
        outline: none;
        border: 1px solid #778188;
        border-bottom: 1px solid #778188 !important;
      }
      .prep-messagesfix-enabled .p-component {
        .p-multiselect-label,
        .p-inputtext {
          padding-left: 20px !important;
        }
      }
      .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
      .p-multiselect-panel
        .p-multiselect-items
        .p-multiselect-item.p-highlight {
        color: #782eb1;
        background: #f5eefc;
      }
    }

    .carpark-multiselect.p-multiselect {
      .p-multiselect-label {
        padding-left: 20px !important;
        color: #bcbfc3;
      }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
      height: initial !important;
    }
    .carpark-multiselect.p-multiselect.p-component .p-checkbox-box.p-highlight {
      background-color: #782eb1;
      border-color: #782eb1;
    }
  }
}

p-dialog {
  .voucher-success-popup {
    width: 760px;

    .p-dialog-content {
      padding: 0;
      background: #ffffff;
      border-radius: 20px;
    }
  }
}

.closure-period-p-dialog {
  .p-dialog-header {
    display: none;
  }
  app-closure-period-popup {
    span.p-calendar.closer-period-date {
      width: 450px;

      input {
        padding-left: 40px;
      }
    }

    app-custom-checkbox {
      label {
        padding: 0;
      }
      input:checked ~ label {
        background-color: #fff !important;
      }
    }
  }
  .p-dialog-content {
    padding: 0;
    border-radius: 15px;
  }
}
app-voucher-report {
  app-help-popover .redeemed-value-help-tooltip {
    .content {
      min-width: 260px !important;
      padding: 8px 12px !important;
    }
  }
}
